import base_config from "./base_config";

const beta_config = {
  ...base_config,
  STAGE: "beta",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_ttcebY1zR",
    aws_user_pools_web_client_id: "37e2vc1v1s80nmbgjslk8dd4vi",
    oauth: {
      domain: "wise-general-cog-beta.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },
  // Base url
  BASE_URL: "https://0rmvz334f0.execute-api.us-east-1.amazonaws.com/beta",
};

export default beta_config;
