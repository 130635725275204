import ReactDOM from "react-dom/client";
import App from "./App";
// integrate Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
// font awesome
import "@fortawesome/fontawesome-free/css/all.css";
// polaris global css
// https://polaris.a2z.com/develop/styles/global_styles/
import "@amzn/awsui-global-styles/polaris.css";
// our own library, contains css style and colors
// https://code.amazon.com/packages/LENS-React-UI/blobs/mainline/--/src/index.css
import "@amzn/lens-react-ui/lib/main.min.css";
// bind redux
import { Provider } from "react-redux";
import store from "./redux/store";
// react router dom
import { BrowserRouter } from "react-router-dom";
// load config
import { loadAppConfig } from "./config/config";
// global css
import "./index.css";
import "./config/color-config.css";

loadAppConfig().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <div className="awsui">
          <App />
        </div>
      </BrowserRouter>
    </Provider>
  );
});
