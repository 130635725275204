import { combineReducers } from "redux";
import chatReducer from "./reducers/chat-reducer";

//reducer list
const reducers = {
  chatReducer,
};

// combine all reducers to rootReducer
const rootReducer = combineReducers(reducers);

export default rootReducer;
