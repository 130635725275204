const chatType = {
  CHAT_RESPONSE: "CHAT_RESPONSE",
  CHAT_RESPONSE_COMMIT: "CHAT_RESPONSE_COMMIT",
  CHAT_RESPONSE_ROLLBACK: "CHAT_RESPONSE_ROLLBACK",

  CLEAR_CHAT_RESPONSE: "CLEAR_CHAT_RESPONSE",

  KNOWLEDGE_BASES: "KNOWLEDGE_BASES",
  KNOWLEDGE_BASES_COMMIT: "KNOWLEDGE_BASES_COMMIT",
  KNOWLEDGE_BASES_ROLLBACK: "KNOWLEDGE_BASES_ROLLBACK",

  UPLOAD_DOC: "UPLOAD_DOC",
  UPLOAD_DOC_COMMIT: "UPLOAD_DOC_COMMIT",
  UPLOAD_DOC_ROLLBACK: "UPLOAD_DOC_ROLLBACK",
};

export default chatType;
