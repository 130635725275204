import { SideNavigationProps } from "@amzn/awsui-components-react-v3/polaris";
import { paths } from "../config/route-paths";

export const NAV_ITEMS: SideNavigationProps.Item[] = [
  { type: "link", text: "Chat Assistant", href: paths.CHAT_ASSISTANT },
  { type: "link", text: "Add New Documents", href: paths.ADD_NEW_DOCUMENTS },
  { type: "link", text: "Help", href: paths.HELP_SECTION },
];

export const HEADERS = {
  APP_NAME: "Merlin KMS",
  CHAT_HEADER: "Chat Assistant",
  ADD_NEW_DOCUMENTS_HEADER: "Add New Documents",
  HELP_SECTION_HEADER: "Help Section - Knowledge Management System (KMS)",
  KNOWLEDGE_BASE: "Knowledge Base",
};

export const ALERT_TYPE_HEADERS_MAP = {
  success: "Submitted successfully",
  error: "Error while submitting",
};