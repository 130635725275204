// general constants
const constants = {
  INITIATIVE: "masterlegalguide",

  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",

  // These are used for dispatching/listening to custom Hub events.
  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_BEFORE_START: 10,
};

export default constants;
