import base_config from "./base_config";

const prod_config = {
  ...base_config,
  STAGE: "prod",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_7jaT8GnzE",
    aws_user_pools_web_client_id: "7h5ec3if4vohn56bdh9ol04p8v",
    oauth: {
      domain: "wise-general-cog-prod.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },
  // Base url
  BASE_URL: "https://ql12erb7uh.execute-api.us-east-1.amazonaws.com/prod",
};

export default prod_config;
