import { Route, Redirect, Switch } from "react-router-dom";

import Layout from "./components/layout/layout";
import { paths } from "./config/route-paths";
import ChatAssistant from "./components/chat-assistant/chat-assistant";
import AddNewDocuments from "./components/add-new-documents/add-new-documents";
import HelpSection from "./components/help-section/help-section";

const MainRouter = () => (
  <Layout>
    <Switch>
      <Route path={paths.CHAT_ASSISTANT} exact component={ChatAssistant} />
      <Route path={paths.ADD_NEW_DOCUMENTS} exact component={AddNewDocuments} />
      <Route path={paths.HELP_SECTION} exact component={HelpSection} />

      {/* redirect */}
      <Redirect to={paths.CHAT_ASSISTANT} />
    </Switch>
  </Layout>
);

export default MainRouter;
