import constants from "../../config/constants";
import chatType from "../types/chat-type";

const initialState = {
  get_status: constants.LOADING_BEFORE_START,
  knowledge_bases_status: constants.LOADING_BEFORE_START,
  upload_doc_status: constants.LOADING_BEFORE_START,
  chatResponse: null,
  knowledgeBases: null,
  uploadDocResponse: null,
};

const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case chatType.CHAT_RESPONSE: {
      return {
        ...state,
        get_status: constants.LOADING_LOAD,
        chatResponse: null,
      };
    }
    case chatType.CHAT_RESPONSE_COMMIT: {
      return {
        ...state,
        get_status: constants.LOADING_SUCCESS,
        chatResponse: action.payload,
      };
    }
    case chatType.CHAT_RESPONSE_ROLLBACK: {
      return {
        ...state,
        get_status: constants.LOADING_FAIL,
        chatResponse: action.payload?.error || action.payload,
      };
    }
    case chatType.CLEAR_CHAT_RESPONSE: {
      return {
        ...state,
        chatResponse: null,
      };
    }
    case chatType.KNOWLEDGE_BASES: {
      return {
        ...state,
        knowledge_bases_status: constants.LOADING_LOAD,
      };
    }
    case chatType.KNOWLEDGE_BASES_COMMIT: {
      return {
        ...state,
        knowledge_bases_status: constants.LOADING_SUCCESS,
        knowledgeBases: action.payload,
      };
    }
    case chatType.KNOWLEDGE_BASES_ROLLBACK: {
      return {
        ...state,
        knowledge_bases_status: constants.LOADING_FAIL,
      };
    }
    case chatType.UPLOAD_DOC: {
      return {
        ...state,
        upload_doc_status: constants.LOADING_LOAD,
        uploadDocResponse: null,
      };
    }
    case chatType.UPLOAD_DOC_COMMIT: {
      return {
        ...state,
        upload_doc_status: constants.LOADING_SUCCESS,
        uploadDocResponse: action.payload,
      };
    }
    case chatType.UPLOAD_DOC_ROLLBACK: {
      return {
        ...state,
        upload_doc_status: constants.LOADING_FAIL,
        uploadDocResponse: action.payload?.exception || action.payload,
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
