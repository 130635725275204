import {
  ContentLayout,
  Header,
  SpaceBetween,
  Box,
  ExpandableSection,
} from "@amzn/awsui-components-react-v3/polaris";

import { HEADERS } from "../../utils/constants";
import { HELP_SECTION_HEADERS, HELP_SECTION_TEXTS } from "./constants";

const HelpSection = () => {
  return (
    <ContentLayout
      header={<Header variant="h1">{HEADERS.HELP_SECTION_HEADER}</Header>}
    >
      <SpaceBetween size="l">
        <Box>{HELP_SECTION_TEXTS.WELCOME_MESSAGE}</Box>

        <ExpandableSection
          defaultExpanded
          headerText={HELP_SECTION_HEADERS.GETTING_STARTED}
        >
          <SpaceBetween size="m">
            <Box variant="p">{HELP_SECTION_TEXTS.GETTING_STARTED}</Box>

            <ol>
              <li>
                <strong>{HELP_SECTION_HEADERS.ACCESSING_KMS_UI}:</strong>
                <ul>
                  <li>{HELP_SECTION_TEXTS.ACCESSING_KMS_UI}</li>
                </ul>
              </li>
              <li>
                <strong>{HELP_SECTION_HEADERS.AI_POWERED_SEARCH}:</strong>
                <ul>
                  <li>{HELP_SECTION_TEXTS.AI_POWERED_SEARCH}</li>
                  <li>{HELP_SECTION_TEXTS.PLEASE_REFER}</li>
                </ul>
              </li>
              <li>
                <strong>{HELP_SECTION_HEADERS.SYNC_NEW_DOCUMENTS}:</strong>
                <ul>
                  <li>{HELP_SECTION_TEXTS.SYNC_NEW_DOCUMENTS}</li>
                </ul>
              </li>
              <li>
                <strong>{HELP_SECTION_HEADERS.VIEWING_RESULTS}:</strong>
                <ul>
                  <li>{HELP_SECTION_TEXTS.VIEWING_RESULTS}</li>
                </ul>
              </li>
            </ol>
          </SpaceBetween>
        </ExpandableSection>

        <ExpandableSection
          defaultExpanded
          headerText={HELP_SECTION_HEADERS.TROUBLESHOOTING_SUPPORT}
        >
          <SpaceBetween size="m">
            <Box variant="p">{HELP_SECTION_TEXTS.TROUBLESHOOTING_SUPPORT}</Box>

            <Box variant="h4">{HELP_SECTION_HEADERS.COMMON_ISSUES}:</Box>
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: HELP_SECTION_TEXTS.COMMON_ISSUES,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: HELP_SECTION_TEXTS.CONTENT_NOT_LOADING,
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: HELP_SECTION_TEXTS.SUPPORT_TEAM_INFO,
                }}
              />
            </ul>
          </SpaceBetween>
        </ExpandableSection>

        <ExpandableSection
          defaultExpanded
          headerText={HELP_SECTION_HEADERS.FUTURE_ENHANCEMENTS}
        >
          <Box variant="p">{HELP_SECTION_TEXTS.FUTURE_ENHANCEMENTS}</Box>
        </ExpandableSection>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default HelpSection;
