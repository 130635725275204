import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  FormField,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  Select,
  SelectProps,
  Container,
  Input,
  Alert,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";

import constants from "../../config/constants";
import { ALERT_TYPE_HEADERS_MAP, HEADERS } from "../../utils/constants";
import { getKnowledgeBases, uploadDoc } from "../../redux/actions/chat-action";
import layoutStyles from "../layout/layout-header.module.css";
import {
  DATA_SOURCE,
  DATA_SOURCE_OPTIONS,
  DATA_SOURCE_FIELDS_MAPPING,
} from "./constants";
import { AlertT, InputFieldT, SelectFieldT } from "./types";

const AddNewDocuments = () => {
  const dispatch = useDispatch();

  const {
    knowledgeBases,
    knowledge_bases_status,
    uploadDocResponse,
    upload_doc_status,
  } = useSelector((state: any) => state.chatReducer);

  const knowledgeBaseOptions = useMemo(
    () =>
      knowledgeBases?.map(({ name, knowledgeBaseId }) => ({
        label: name,
        value: knowledgeBaseId,
      })),
    [knowledgeBases]
  );

  const [selectedKnowledgeBase, setSelectedKnowledgeBase] =
    useState<SelectProps.Option | null>(null);
  const [selectedDataSource, setSelectedDataSource] =
    useState<SelectProps.Option | null>(null);
  const [selectField, setSelectField] = useState<SelectFieldT | null>(null);
  const [inputField, setInputField] = useState<InputFieldT>(
    DATA_SOURCE_FIELDS_MAPPING.confluence.inputField
  );
  const [alert, setAlert] = useState<AlertT>({
    visible: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    dispatch(getKnowledgeBases());
  }, [dispatch]);

  useEffect(() => {
    if (knowledgeBases?.length && !selectedKnowledgeBase) {
      setSelectedKnowledgeBase(knowledgeBaseOptions[0]);
      setSelectedDataSource(DATA_SOURCE_OPTIONS[0]);
    }
  }, [knowledgeBaseOptions, knowledgeBases?.length, selectedKnowledgeBase]);

  useEffect(() => {
    if (selectedDataSource?.value) {
      setSelectField(
        DATA_SOURCE_FIELDS_MAPPING[selectedDataSource.value]?.selectField
      );
      setInputField(
        DATA_SOURCE_FIELDS_MAPPING[selectedDataSource.value]?.inputField
      );
    }
  }, [selectedDataSource?.value]);

  const handleKnowledgeBaseChange = ({ detail }) => {
    setSelectedKnowledgeBase(detail?.selectedOption);
    setInputField((prev) => ({ ...prev, value: "" }));
  };

  const handleDataSource = ({ detail }) => {
    setSelectedDataSource(detail?.selectedOption);
    setInputField((prev) => ({ ...prev, value: "" }));
  };

  const handleInputValueChange = ({ detail }) =>
    setInputField((prev) => ({ ...prev, value: detail?.value }));

  const handleSubmit = () => {
    const commonPayload = { knowledgeBaseId: selectedKnowledgeBase?.value };

    const specificPayload = (() => {
      switch (selectedDataSource?.value) {
        case DATA_SOURCE.confluence:
          return {
            space: selectField?.selectedOption?.value,
            pageTitle: inputField?.value,
          };
        case DATA_SOURCE.jira:
          return {
            project: selectField?.selectedOption?.value,
            issueId: inputField?.value,
          };
        case DATA_SOURCE.quip:
          return { documentId: inputField?.value };
        default:
          return {};
      }
    })();

    dispatch(
      uploadDoc(selectedDataSource?.value, {
        ...commonPayload,
        ...specificPayload,
      })
    );
  };

  useEffect(() => {
    if (upload_doc_status === constants.LOADING_FAIL) {
      setAlert({ visible: true, type: "error", message: uploadDocResponse });
    }

    if (upload_doc_status === constants.LOADING_SUCCESS && uploadDocResponse) {
      setAlert({ visible: true, type: "success", message: uploadDocResponse });
      setInputField((prev) => ({ ...prev, value: "" }));
    }
  }, [upload_doc_status, uploadDocResponse]);

  const dismissAlert = () => {
    setAlert((prev) => ({ ...prev, visible: false }));
  };

  const submitting = [knowledge_bases_status, upload_doc_status].includes(
    constants.LOADING_LOAD
  );

  return (
    <ContentLayout
      header={
        <div className={layoutStyles.headerContainer}>
          <Header variant="h1">{HEADERS.ADD_NEW_DOCUMENTS_HEADER}</Header>
          <div className={layoutStyles.headerContainer}>
            <div className={layoutStyles.headerContent}>
              <Header variant="h3">{HEADERS.KNOWLEDGE_BASE}</Header>
            </div>
            <div className={layoutStyles.headerContent}>
              {knowledge_bases_status === constants.LOADING_LOAD ? (
                <StatusIndicator type="loading">Loading</StatusIndicator>
              ) : (
                <Select
                  selectedOption={selectedKnowledgeBase}
                  onChange={handleKnowledgeBaseChange}
                  options={knowledgeBaseOptions}
                />
              )}
            </div>
          </div>
        </div>
      }
    >
      {knowledge_bases_status === constants.LOADING_LOAD ? (
        <StatusIndicator type="loading">Loading</StatusIndicator>
      ) : (
        <>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={submitting}
                  loading={submitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <Container>
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Data Source">
                  <Select
                    selectedOption={selectedDataSource}
                    options={DATA_SOURCE_OPTIONS}
                    onChange={handleDataSource}
                  />
                </FormField>
                {selectField && (
                  <FormField label={selectField.label}>
                    <Select
                      selectedOption={selectField.selectedOption}
                      options={[selectField.selectedOption]}
                    />
                  </FormField>
                )}
                {inputField && (
                  <FormField label={inputField.label}>
                    <Input
                      type="text"
                      inputMode="text"
                      value={inputField.value}
                      onChange={handleInputValueChange}
                    />
                  </FormField>
                )}
              </SpaceBetween>
            </Container>
          </Form>
          <br />
          {alert.visible && (
            <Alert
              type={alert.type}
              dismissible
              onDismiss={dismissAlert}
              header={ALERT_TYPE_HEADERS_MAP[alert.type]}
            >
              {alert.message}
            </Alert>
          )}
        </>
      )}
    </ContentLayout>
  );
};

export default AddNewDocuments;
