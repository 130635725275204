import beta_config from "./stage-config/beta_config";
import prod_config from "./stage-config/prod_config";

export let config = {};

function timeout(ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error("timeout"));
    }, ms);
    promise.then(resolve, reject);
  });
}

export async function loadAppConfig() {
  console.log("[Fetch Config]Start async call...");
  let stage;
  try {
    stage = await timeout(
      5000,
      fetch(`/config/config.json`).then((response) => response.json())
    );
  } catch (e) {
    console.log("Error occurred while loading config. ", e);
    stage = { stage: "beta" };
  }
  console.log(
    `[Fetch Config]Config loaded for stage: ${JSON.stringify(stage)}`
  );
  switch (stage.stage) {
    case "prod":
      config = prod_config;
      break;
    default:
      config = beta_config;
      break;
  }
  return config;
}
