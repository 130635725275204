class Endpoints {
  /*
        define all you endpoints here
        each endpoint will be in form of function
    */
  sampleEndpoint = (if_you_need_variable: string) =>
    `/this_is_an_example/${if_you_need_variable}`;

  getKMSResponse = () => `/getKMSResponse`;
  getKnowledgeBases = () => `/knowledgeBases`;
  uploadConfluenceDoc = () => `/uploadConfluenceDoc`;
  uploadJiraIssue = () => `/uploadJiraIssue`;
  uploadQuipDoc = () => `/uploadQuipDoc`;

  uploadDocMap = {
    confluence: this.uploadConfluenceDoc,
    jira: this.uploadJiraIssue,
    quip: this.uploadQuipDoc,
  };
}

const endpoints = new Endpoints();
export default endpoints;
