import {
  legacy_createStore as createStore,
  applyMiddleware,
  Middleware,
} from "redux";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import rootSaga from "../sagas/rootSaga";
import rootReducer from "./root-reducer";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// configure logger middleware
const loggerMiddleware = createLogger({ collapsed: true }) as Middleware;

// create middleware list
const middleware = [sagaMiddleware, loggerMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;
