import Amplify from "@aws-amplify/core";

import { config } from "./config/config";
import Router from "./router";

const App = () => {
  Amplify.configure(config.AMPLIFY_CONFIG);
  return <Router />;
};

export default App;
