export const DATA_SOURCE = {
  confluence: "confluence",
  jira: "jira",
  quip: "quip",
};

export const DATA_SOURCE_OPTIONS = [
  { label: "Confluence", value: DATA_SOURCE.confluence },
  { label: "JIRA", value: DATA_SOURCE.jira },
  { label: "Quip", value: DATA_SOURCE.quip },
];

export const DATA_SOURCE_FIELDS_MAPPING = {
  confluence: {
    selectField: {
      label: "Space",
      selectedOption: { label: "WDI", value: "WDI" },
    },
    inputField: { label: "Page Title", value: "" },
  },
  jira: {
    selectField: {
      label: "Project",
      selectedOption: { label: "LENS", value: "LENS" },
    },
    inputField: { label: "Issue ID", value: "" },
  },
  quip: {
    inputField: { label: "Document ID", value: "" },
  },
};
