export const HELP_SECTION_HEADERS = {
  GETTING_STARTED: "Getting Started with KMS",
  ACCESSING_KMS_UI: "Accessing the KMS UI",
  AI_POWERED_SEARCH: "Using the AI-Powered Search",
  SYNC_NEW_DOCUMENTS: "Sync new confluence documents",
  VIEWING_RESULTS: "Viewing and Interacting with Results",
  TROUBLESHOOTING_SUPPORT: "Troubleshooting & Support",
  COMMON_ISSUES: "Common Issues",
  FUTURE_ENHANCEMENTS: "Future Enhancements",
};

export const HELP_SECTION_TEXTS = {
  WELCOME_MESSAGE: `Welcome to the Knowledge Management System (KMS), designed to
      streamline your information retrieval process by integrating various
      knowledge sources such as Confluence, Quip, JIRA, PDFs, and Word
      documents. Our system leverages advanced Generative AI to provide
      accurate and contextually relevant responses, helping you find the
      information you need more efficiently.`,

  GETTING_STARTED: `
      In this initial launch, our KMS is focused on Confluence support
      via the user interface (UI). Here's how you can make the most of
      this feature:
      `,
  ACCESSING_KMS_UI: `
      Navigate through various knowledge bases content directly
      via browser.
      `,
  AI_POWERED_SEARCH: `
      Enter your query in the search bar. The AI will analyze the
      context and provide the most relevant information.
      `,
  SYNC_NEW_DOCUMENTS: `
      Add new confluence documents to keep the information up to
      date.
      `,
  VIEWING_RESULTS: `
      Search results will be displayed with snippets of content to
      help you quickly identify relevant information.
      `,

  TROUBLESHOOTING_SUPPORT: `
      If you encounter any issues while using the KMS or have specific
      queries regarding its functionality, our support team is here to
      assist you.
      `,
  COMMON_ISSUES: `
      <strong>Search Results Not Relevant:</strong> Try refining your
      query with more specific keywords or phrases.
      `,
  CONTENT_NOT_LOADING: `
      <strong>Content Not Loading:</strong> Ensure you have a stable
      internet connection and try refreshing the page.
      `,
  SUPPORT_TEAM_INFO: `
      For any unresolved issues or further assistance, please reach
      out to our support team by cutting a ticket to
      <strong> CTI Lab126/Wise/KMS</strong>. Our team is dedicated to
      resolving your queries promptly.
      `,
  FUTURE_ENHANCEMENTS: `
      This is just the beginning! In future phases, we will be expanding
      KMS to include integration with other platforms such as Quip, JIRA,
      and document formats like PDFs and Word documents. Stay tuned for
      updates and new features that will further enhance your knowledge
      management experience.
      `,
  PLEASE_REFER: `For more refined results, use specific keywords or phrases related to your query.`,
};
