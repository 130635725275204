import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import chatType from "../types/chat-type";

export const getChatResponse = ({ query, sessionId, knowledgeBaseId }) => ({
  type: chatType.CHAT_RESPONSE,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getKMSResponse(),
        method: "POST",
        json: { query, sessionId, knowledgeBaseId },
      },
      commit: {
        type: chatType.CHAT_RESPONSE_COMMIT,
      },
      rollback: {
        type: chatType.CHAT_RESPONSE_ROLLBACK,
      },
    },
  },
});

export const clearChatResponse = () => ({
  type: chatType.CLEAR_CHAT_RESPONSE,
});

export const getKnowledgeBases = () => ({
  type: chatType.KNOWLEDGE_BASES,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.getKnowledgeBases(),
        method: "GET",
      },
      commit: {
        type: chatType.KNOWLEDGE_BASES_COMMIT,
      },
      rollback: {
        type: chatType.KNOWLEDGE_BASES_ROLLBACK,
      },
    },
  },
});

export const uploadDoc = (dataSource, json) => ({
  type: chatType.UPLOAD_DOC,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.uploadDocMap[dataSource](),
        method: "POST",
        json,
      },
      commit: {
        type: chatType.UPLOAD_DOC_COMMIT,
      },
      rollback: {
        type: chatType.UPLOAD_DOC_ROLLBACK,
      },
    },
  },
});
