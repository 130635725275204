import { useEffect, useState } from "react";
import styles from "./chat-assistant.module.css";

type PropsT = {
  text?: string;
  delay?: number;
};

const StreamingText = ({ text = "", delay = 20 }: PropsT) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    const words = text ? text.split(" ") : [];
    let currentWordIndex = 0;

    const interval = setInterval(() => {
      if (currentWordIndex < words.length - 1) {
        setDisplayText((prevText) =>
          currentWordIndex === 0
            ? words[currentWordIndex]
            : `${prevText} ${words[currentWordIndex]}`
        );
        currentWordIndex++;
      } else {
        clearInterval(interval);
      }
    }, delay);

    return () => clearInterval(interval);
  }, [delay, text]);

  return <p className={styles.displayText}>{displayText}</p>;
};

export default StreamingText;
