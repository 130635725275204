import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentLayout,
  Header,
  SpaceBetween,
  Textarea,
  Button,
  Box,
  Icon,
  Grid,
  Spinner,
  Select,
  SelectProps,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";

import constants from "../../config/constants";
import { HEADERS } from "../../utils/constants";
import {
  clearChatResponse,
  getChatResponse,
  getKnowledgeBases,
} from "../../redux/actions/chat-action";
import layoutStyles from "../layout/layout-header.module.css";
import StreamingText from "./streaming-text";
import styles from "./chat-assistant.module.css";
import { ERROR_MESSAGES, PLACEHOLDERS } from "./constants";

type MessageT = {
  role: "user" | "assistant" | "system";
  content: string;
  fileLocation?: string;
  contentText?: string;
};

type ChatMessagePropsT = {
  message: MessageT;
};

const ChatMessage = ({ message }: ChatMessagePropsT) => {
  const [expanded, setExpanded] = useState(false);
  const [contentExpanded, setContentExpanded] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message.content);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Could not copy text: ", err);
    }
  };

  return (
    <Grid
      gridDefinition={[{ colspan: 1 }, { colspan: 11 }]}
      disableGutters={true}
    >
      <div
        className={`${styles.iconContainer} ${
          message.role === "user" ? styles.userIcon : styles.assistantIcon
        }`}
      >
        <Icon
          name={message.role === "user" ? "user-profile" : "envelope"}
          size="medium"
        />
      </div>
      <div
        className={`${styles.messageContent} ${
          message.role === "user" ? styles.userMessage : styles.assistantMessage
        }`}
      >
        {message.role === "user" ? (
          <div>{message.content}</div>
        ) : (
          <>
            <div className={styles.assistantMessageHeader}>
              <Button
                iconName={`${copySuccess ? "check" : "copy"}`}
                variant="inline-link"
                iconAlign="left"
                onClick={handleCopy}
              >
                {`${copySuccess ? "Copied" : "Copy"}`}
              </Button>
            </div>
            <StreamingText text={message.content} />
            {message.fileLocation && (
              <div className={styles.sourcesSection}>
                <Button
                  iconName={`angle-${expanded ? "up" : "down"}`}
                  iconAlign="right"
                  onClick={() => setExpanded(!expanded)}
                >
                  Sources
                </Button>
                {expanded && (
                  <Box className={styles.sourcesContent}>
                    <span>{message.fileLocation}&nbsp;</span>
                    <Button
                      iconName={`angle-${contentExpanded ? "up" : "down"}`}
                      variant="icon"
                      onClick={() => setContentExpanded(!contentExpanded)}
                    />
                    {contentExpanded && (
                      <div className={styles.contentTextFull}>
                        {message.contentText}
                      </div>
                    )}
                  </Box>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Grid>
  );
};

const ChatAssistant = () => {
  const dispatch = useDispatch();

  const { chatResponse, get_status, knowledgeBases, knowledge_bases_status } =
    useSelector((state: any) => state.chatReducer);

  const knowledgeBaseOptions = useMemo(
    () =>
      knowledgeBases?.map(({ name, knowledgeBaseId }) => ({
        label: name,
        value: knowledgeBaseId,
      })),
    [knowledgeBases]
  );

  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState<MessageT[]>([]);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] =
    useState<SelectProps.Option | null>(null);

  useEffect(() => {
    dispatch(getKnowledgeBases());
  }, [dispatch]);

  useEffect(() => {
    if (knowledgeBases?.length && !selectedKnowledgeBase) {
      setSelectedKnowledgeBase(knowledgeBaseOptions[0]);
    }
  }, [knowledgeBaseOptions, knowledgeBases?.length, selectedKnowledgeBase]);

  const handleKnowledgeBaseChange = ({ detail }) => {
    setSelectedKnowledgeBase(detail?.selectedOption);
    dispatch(clearChatResponse());
    setConversation([]);
  };

  const handleSubmit = () => {
    if (input.trim()) {
      const userMessage: MessageT = { role: "user", content: input };
      setConversation((prev) => [...prev, userMessage]);
      dispatch(
        getChatResponse({
          query: input,
          sessionId: chatResponse?.session_id,
          knowledgeBaseId: selectedKnowledgeBase?.value,
        })
      );
      setInput("");
    }
  };

  useEffect(() => {
    if (get_status === constants.LOADING_SUCCESS && chatResponse?.response) {
      const assistantMessage: MessageT = {
        role: "assistant",
        content: chatResponse.response,
        fileLocation: (chatResponse.file_location || "").split("/").pop(),
        contentText: chatResponse.content_text,
      };
      setConversation((prev) => [...prev, assistantMessage]);
    }

    if (get_status === constants.LOADING_FAIL) {
      const errorMessage: MessageT = {
        role: "system",
        content: ERROR_MESSAGES.PROCESSING_ERROR,
      };
      setConversation((prev) => [...prev, errorMessage]);
    }
  }, [chatResponse, get_status]);

  const isSubmitDisabled =
    [get_status, knowledge_bases_status].includes(constants.LOADING_LOAD) ||
    !input.trim();

  return (
    <ContentLayout
      header={
        <div className={layoutStyles.headerContainer}>
          <Header variant="h1">{HEADERS.CHAT_HEADER}</Header>
          <div className={layoutStyles.headerContainer}>
            <div className={layoutStyles.headerContent}>
              <Header variant="h3">{HEADERS.KNOWLEDGE_BASE}</Header>
            </div>
            <div className={layoutStyles.headerContent}>
              {knowledge_bases_status === constants.LOADING_LOAD ? (
                <StatusIndicator type="loading">Loading</StatusIndicator>
              ) : (
                <Select
                  selectedOption={selectedKnowledgeBase}
                  onChange={handleKnowledgeBaseChange}
                  options={knowledgeBaseOptions}
                />
              )}
            </div>
          </div>
        </div>
      }
    >
      <div className={styles.chatContainer}>
        <div className={styles.messageContainer}>
          <SpaceBetween size="l">
            {conversation.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
            {get_status === constants.LOADING_LOAD && (
              <Box textAlign="center">
                <Spinner size="normal" />
              </Box>
            )}
          </SpaceBetween>
        </div>
        <div>
          <Textarea
            value={input}
            onChange={({ detail }) => setInput(detail.value)}
            placeholder={PLACEHOLDERS.CHAT_INPUT}
            disabled={[get_status, knowledge_bases_status].includes(
              constants.LOADING_LOAD
            )}
          />
          <Box float="right" margin={{ top: "xs" }}>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </Box>
        </div>
      </div>
    </ContentLayout>
  );
};

export default ChatAssistant;
