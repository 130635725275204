import React from "react";
import { AppLayout, SideNavigation } from "@amzn/awsui-components-react-v3";

import { HEADERS, NAV_ITEMS } from "../../utils/constants";

type PropsT = {
  children?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal;
};

const Layout = ({ children }: PropsT) => {
  return (
    <AppLayout
      navigation={
        <SideNavigation
          items={NAV_ITEMS}
          header={{ text: HEADERS.APP_NAME, href: "#" }}
        />
      }
      content={children}
    />
  );
};

export default Layout;
